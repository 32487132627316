import React, { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import lsfSentiment from '../../resources/lsf.png';
import ProjectItem from './ProjectItem';
import lsf from '../../resources/lsf.png';
import leagueAI from '../../resources/leagueAI.png';
import DLexa from '../../resources/DLexa.png';
import Title from '../shared/Title';
import LeagueItems from '../../resources/LeagueItems.png';
import { ScreenContext } from '../../App';

const projects = [
	{
		name: 'League Items',
		text: 'League Items is a web app that calculates statistics of item combinations for the game League of Legends',
		img: LeagueItems,
		tech: 'React, Typescript, Express, Apollo GraphQL, MongoDB, Riot API',
		links: [
			'https://github.com/thomaszhou01/LeagueItems',
			'https://league-items.herokuapp.com/',
		],
	},
	{
		name: 'LSFSentiment',
		text: 'Web app that calculates community sentiment on the popular subreddit LivestreamFails using natural language processing. Frontend UI developed using the React framework and backend REST API created with Django. Integrated PostgreSQL database to store data retrieved from over 1000 posts per hour. Used Twitch and Reddit API to acquire data and the NLTK library to determine comment sentiment',
		img: lsf,
		tech: 'React, Typescript, Django, Python, PostgreSQL, Twitch API, Reddit API, NLP',
		links: [
			'https://github.com/thomaszhou01/LSFSentiment',
			'https://lsf-sentiment.herokuapp.com',
		],
	},
	{
		name: 'League AI',
		text: 'Developed Python application that predicts the winner of a League of Legends game in real time using machine learning. Trained a custom Tensorflow neural network using 10000 games to identify trends and patterns that will predict the winning team. Designed and built a GUI using Tkinter which displays live game statistics as well as the prediction percentage of each team. Neural network has a 96% accuracy with test data from 1000 new League of Legends games',
		img: leagueAI,
		tech: 'Python, Tensorflow, Pandas, Matplotlib, Riot API, TKinter',
		links: ['https://github.com/thomaszhou01/leagueAI'],
	},
	{
		name: 'DLexa',
		text: 'Built a Discord virtual assistant bot that plays music and can receive voice commands to execute tasks used by over 100 users. Created using Javascript and uses Discord.js, YTDL, and Google Cloud APIs (Speech to Text API, Search, Weather). Hosted on an AWS Lightsail instance',
		img: DLexa,
		tech: 'Javascript, Node.JS, AWS Lightsail, Google Cloud API, NLP',
		links: ['https://github.com/icecapped/Dlexa'],
	},
];

function Projects(props: any) {
	const screen = useContext(ScreenContext);

	return (
		<Box
			display={'flex'}
			justifyContent={'center'}
			flexDirection="column"
			alignItems="center"
			p={3}
			py={7}
			id={'projects'}
		>
			<Title num={4} title={'Projects'} isDesktop={screen} />
			{projects.map((project, index) => {
				return (
					<ProjectItem
						name={project.name}
						text={project.text}
						img={project.img}
						key={project.name}
						links={project.links}
						projectNum={index}
						tech={project.tech}
					/>
				);
			})}
		</Box>
	);
}

export default Projects;
