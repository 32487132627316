import {
	AppBar,
	Box,
	Button,
	Drawer,
	IconButton,
	Slide,
	Toolbar,
	Typography,
	useScrollTrigger,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ScreenContext } from '../../App';
import thomasZhouResume from '../../resources/thomasZhouResume.pdf';
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
	children: React.ReactElement;
}
function HideOnScroll(props: Props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}
const tabs = ['about', 'skills', 'experience', 'projects', 'contact'];

function Navbar(props: any) {
	const screen = useContext(ScreenContext);
	const [mobileOpen, setMobileOpen] = useState(false);

	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen);
	}
	const scrollWithOffset = (el: any) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = 0;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	return (
		<React.Fragment>
			<HideOnScroll {...props}>
				<AppBar style={{ background: 'transparent', boxShadow: 'none' }}>
					{screen ? (
						<Toolbar>
							<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
								Thomas Zhou
							</Typography>
							{tabs.map((tab, index) => {
								return (
									<BrowserRouter>
										<HashLink
											to={'/#' + tab}
											style={{ textDecoration: 'none' }}
											scroll={scrollWithOffset}
										>
											<Button sx={{ mr: 2 }} key={tab}>
												<Typography component="span" color="primary">
													{index + 1 + '. '}
												</Typography>
												<Typography component="span" color="text.primary">
													{tab}
												</Typography>
											</Button>
										</HashLink>
									</BrowserRouter>
								);
							})}

							<Button
								color="primary"
								variant="outlined"
								href={thomasZhouResume}
								target="_blank"
							>
								Resume
							</Button>
						</Toolbar>
					) : (
						<Toolbar>
							<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
								Thomas Zhou
							</Typography>

							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 2 }}
							>
								<MenuIcon />
							</IconButton>
						</Toolbar>
					)}
					<Drawer
						anchor={'right'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
					>
						{tabs.map((tab, index) => {
							return (
								<BrowserRouter>
									<HashLink
										to={'/#' + tab}
										style={{ textDecoration: 'none' }}
										scroll={scrollWithOffset}
									>
										<Button
											sx={{ mr: 2 }}
											key={tab}
											onClick={handleDrawerToggle}
										>
											<Typography component="span" color="primary">
												{index + 1 + '. '}
											</Typography>
											<Typography component="span" color="text.primary">
												{tab}
											</Typography>
										</Button>
									</HashLink>
								</BrowserRouter>
							);
						})}
					</Drawer>
				</AppBar>
			</HideOnScroll>
		</React.Fragment>
	);
}

export default Navbar;
