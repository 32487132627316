import {
  Stack,
  Box,
  IconButton,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ScreenContext } from "../../App";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

const contact = [
  {
    type: "github",
    link: "https://github.com/thomaszhou01",
  },
  {
    type: "linkedin",
    link: "https://www.linkedin.com/in/thomaszhou01",
  },
  {
    type: "email",
    link: "thomas.zhou@uwaterloo.ca",
  },
];

const barHeight = 75;

function Sidebar(props: any) {
  const screen = useContext(ScreenContext);

  return (
    <Box>
      {screen && (
        <Stack position={"fixed"} bottom={0} left={30} direction={"column"}>
          {contact.map((contact: any) => {
            return (
              <IconButton
                href={
                  contact.type.includes("email")
                    ? "mailto:" + contact.link
                    : contact.link
                }
                target="_blank"
                key={contact.link}
                size="large"
              >
                {contact.type.includes("github") ? (
                  <GitHubIcon fontSize="inherit" />
                ) : contact.type.includes("linkedin") ? (
                  <LinkedInIcon fontSize="inherit" />
                ) : (
                  <EmailIcon fontSize="inherit" />
                )}
              </IconButton>
            );
          })}
          <Box height={barHeight} width={"50%"}>
            <Divider orientation="vertical" variant="middle"></Divider>
          </Box>
        </Stack>
      )}
      {screen && (
        <Stack position={"fixed"} bottom={0} right={30} direction={"column"}>
          <Link
            underline="none"
            href="mailto:thomas.zhou@uwaterloo.ca"
            target={"_blank"}
            color={"text.primary"}
            sx={{
              flexGrow: 1,
              writingMode: "vertical-rl",
              "&:hover": { color: "primary.main" },
            }}
          >
            thomas.zhou@uwaterloo.ca
          </Link>
          <Box height={barHeight} width={"50%"}>
            <Divider orientation="vertical" variant="middle"></Divider>
          </Box>
        </Stack>
      )}
    </Box>
  );
}

export default Sidebar;
