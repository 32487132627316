import { Box, IconButton, Link, Typography } from "@mui/material";
import { useContext } from "react";
import { ScreenContext } from "../../App";
import Title from "../shared/Title";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

const contact = [
  {
    type: "github",
    link: "https://github.com/thomaszhou01",
  },
  {
    type: "linkedin",
    link: "https://www.linkedin.com/in/thomaszhou01",
  },
  {
    type: "email",
    link: "thomas.zhou@uwaterloo.ca",
  },
];

function Contact(props: any) {
  const screen = useContext(ScreenContext);
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      id={"contact"}
      p={3}
      pb={20}
    >
      <Title num={5} title={"Contact"} isDesktop={screen} />

      <Box>
        {contact.map((contact: any) => {
          return (
            <IconButton
              href={
                contact.type.includes("email")
                  ? "mailto:" + contact.link
                  : contact.link
              }
              target="_blank"
              key={contact.link}
              size="large"
            >
              {contact.type.includes("github") ? (
                <GitHubIcon fontSize="inherit" />
              ) : contact.type.includes("linkedin") ? (
                <LinkedInIcon fontSize="inherit" />
              ) : (
                <EmailIcon fontSize="inherit" />
              )}
            </IconButton>
          );
        })}
      </Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        I am always looking for and open to new opportunities! Feel free to
        contact me at{" "}
        <Link
          underline="none"
          href="mailto:thomas.zhou@uwaterloo.ca"
          target={"_blank"}
        >
          thomas.zhou@uwaterloo.ca!
        </Link>
      </Typography>
    </Box>
  );
}

export default Contact;
