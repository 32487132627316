import React, { useContext, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Title from '../shared/Title';
import { ScreenContext } from '../../App';

const skills = [
    {
        name: 'Languages',
        contents: [
            'Go',
            'Typescript',
            'Javascript',
            'Python',
            'Java',
            'C++',
            'HTML',
            'CSS',
        ],
    },
    {
        name: 'Frameworks',
        contents: ['React.JS', 'Spring Boot', 'Django', 'Tensorflow', 'OpenCV'],
    },
    {
        name: 'Technologies',
        contents: ['AWS', 'Docker', 'PostgreSQL', 'MongoDB', 'Git'],
    },
];

function Skills(props: any) {
    const screen = useContext(ScreenContext);

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection="column"
            alignItems="center"
            p={3}
            py={7}
            id={'skills'}
        >
            <Title num={2} title={'Skills'} isDesktop={screen} />
            <Box>
                {skills.map((skillType, index) => {
                    return (
                        <Box sx={{ m: 2 }} key={skillType.name + index}>
                            <Typography component="span" variant="h5">
                                {skillType.name}:
                            </Typography>
                            {skillType.contents.map((skill, index) => {
                                return (
                                    <Button
                                        variant="outlined"
                                        sx={{ ml: screen ? 2 : 0 }}
                                        key={skill}
                                    >
                                        {skill}
                                    </Button>
                                );
                            })}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}

export default Skills;
