import { Divider, Typography } from "@mui/material";

function Title(props: any) {
  return (
    <Typography
      variant="h2"
      sx={{ mb: 2, width: props.isDesktop ? "50%" : "100%" }}
    >
      <Divider textAlign="center">
        <Typography component="span" color="secondary" variant="h2">
          {props.num}.
        </Typography>{" "}
        {props.title}
      </Divider>
    </Typography>
  );
}

export default Title;
