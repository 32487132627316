import { Box, Tab, Tabs, Typography, Divider } from "@mui/material";
import { useContext, useState } from "react";
import { ScreenContext } from "../../App";
import Title from "../shared/Title";
import experience from "./ExperienceData";
import { v4 as uuidv4 } from "uuid";

interface TabPanelProps {
  index: number;
  value: number;
  company: string;
  roles: string;
  duration: string;
  notes: string[];
}
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props: TabPanelProps) {
  const { value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {props.roles}
            <Typography variant="h6" color={"secondary"} component={"span"}>
              {" @"}
              {props.company}
            </Typography>
          </Typography>

          <Typography variant="body2" color={"secondary.light"} sx={{ mb: 1 }}>
            {props.duration}
          </Typography>
          {props.notes.map((note, index) => {
            return <li>{note}</li>;
          })}
        </Box>
      )}
    </div>
  );
}

function Experience(props: any) {
  const [value, setValue] = useState(0);
  const screen = useContext(ScreenContext);

  const tabData = experience.map((company, index) => {
    return (
      <TabPanel
        value={value}
        index={index}
        company={company.company}
        roles={company.role}
        duration={company.duration}
        notes={company.notes}
        key={uuidv4()}
      />
    );
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection="column"
      alignItems="center"
      p={3}
      py={7}
      id={"experience"}
    >
      <Title num={3} title={"Experience"} isDesktop={screen} />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          height: "auto",
          width: screen ? 1000 : "auto",
          mt: screen ? 5 : 0,
        }}
      >
        <Tabs
          orientation={screen ? "vertical" : "horizontal"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          sx={{
            borderRight: screen ? 1 : 0,
            boarderBottom: screen ? 0 : 1,
            borderColor: "divider",
          }}
        >
          {experience.map((company, index) => {
            return (
              <Tab
                label={company.company}
                {...a11yProps(index)}
                key={company.company}
              />
            );
          })}
        </Tabs>
        {screen && tabData}
      </Box>
      {!screen && tabData}
    </Box>
  );
}

export default Experience;
