import React, { createContext, useContext } from "react";
import logo from "./logo.svg";
import Hero from "./components/hero/Hero";
import Skills from "./components/skills/Skills";
import Experience from "./components/experience/Experience";
import theme from "./components/Themes";
import { ThemeProvider, CssBaseline } from "@mui/material";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Projects from "./components/projects/Projects";
import ScreenSize from "./components/shared/ScreenSize";
import Contact from "./components/contact/Contact";
import Watermark from "./components/Watermark";
import Sidebar from "./components/navbar/Sidebar";

export const ScreenContext = createContext(true);

function App() {
  const screenSize = ScreenSize();
  return (
    <ScreenContext.Provider value={screenSize}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Sidebar />
        <Hero />
        <About />
        <Skills />
        <Experience />
        <Projects />
        <Contact />
        <Watermark />
      </ThemeProvider>
    </ScreenContext.Provider>
  );
}

export default App;
