import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Source Sans Pro,sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  palette: {
    mode: "dark",
    background: {
      default: "#011e42",
    },
    primary: {
      main: "#fd7702",
      light: "#02285e",
    },
    secondary: {
      light: "#a5bfe8",
      main: "#fd7702",
    },
    text: { primary: "#c0d1eb" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
