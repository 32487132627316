import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTypewriter } from 'react-simple-typewriter';

function Hero(props: any) {
	const theme = useTheme();
	const { text } = useTypewriter({
		words: [
			'Software Developer',
			'Computer Science Student',
			'Machine Learning Enthusiast',
		],
		loop: 0,
	});

	return (
		<Box
			display={'flex'}
			justifyContent={'center'}
			height={'100vh'}
			alignItems={'center'}
			sx={{ textAlign: 'center' }}
		>
			<Box>
				<Typography variant="h1">
					Hi I'm{' '}
					<Typography variant="h1" component="span" color="secondary">
						Thomas Zhou
					</Typography>
				</Typography>
				<h1 style={{ margin: 'auto 0', fontWeight: 'normal' }}>
					I'm a{' '}
					<span
						style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
					>
						{text}
					</span>
				</h1>
				<Button variant="outlined" href="mailto:thomas.zhou@uwaterloo.ca">
					Lets Get in Touch
				</Button>
			</Box>
		</Box>
	);
}

export default Hero;
