import { Avatar, Box, Divider, Link, Typography } from "@mui/material";
import { useContext } from "react";
import { ScreenContext } from "../../App";
import pfpImg from "../../resources/pfp.jpg";
import Title from "../shared/Title";

function About(props: any) {
  const screen = useContext(ScreenContext);
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      id={"about"}
      p={3}
    >
      <Title num={1} title={"About"} isDesktop={screen} />
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={screen ? "row" : "column"}
        alignItems={"center"}
      >
        <Box width={screen ? "20%" : "80%"}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Hi! My name is Thomas Zhou and I enjoy creating software of many
            forms. From web applications to live analytic tools, I've
            accumulated experience in a wide range of frameworks and languages.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            My interest in technology started with my high school FRC robotics
            team. I was the leader of the Build and Design subteam and I led a
            team of over 20 students. On the team, I was exposed to many
            intriguing technologies such as computer vision and CAD.{" "}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            After the pandemic started, I shifted my focus towards software. I
            developed{" "}
            <Link
              underline="none"
              href="https://github.com/thomaszhou01/leagueAI"
              target={"_blank"}
            >
              LeagueAI
            </Link>
            , a Python application that uses machine learning to predict the
            winner of a League of Legends game in real time. I also created{" "}
            <Link
              underline="none"
              href="https://lsf-sentiment.herokuapp.com"
              target={"_blank"}
            >
              LSF Sentiment
            </Link>
            , a React web app that displays and calculates comment sentiment on
            posts from the subreddit LivestreamFails.
          </Typography>
        </Box>
        <Box sx={{ ml: screen ? 10 : 0 }}>
          <Avatar
            alt="PFP"
            src={pfpImg}
            sx={{ width: screen ? 500 : 300, height: screen ? 500 : 300 }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default About;
