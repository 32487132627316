import { Box, Typography, IconButton, Stack } from "@mui/material";
import { useContext } from "react";
import { ScreenContext } from "../../App";
import GitHubIcon from "@mui/icons-material/GitHub";
import LanguageIcon from "@mui/icons-material/Language";
import { DiPython } from "react-icons/di";

function ProjectItem(props: any) {
  const screen = useContext(ScreenContext);

  return (
    <Stack
      width={screen ? "1000px" : "100%"}
      sx={{ mt: 5 }}
      direction={
        !screen ? "column" : props.projectNum % 2 === 0 ? "row" : "row-reverse"
      }
    >
      <Box
        width={screen ? "45%" : "100%"}
        sx={{ mr: screen ? "auto" : 0, ml: screen ? "auto" : 0 }}
      >
        <Box sx={{ verticalAlign: "center", display: "flex" }}>
          <Typography component={"span"} variant="h4">
            {props.name}
          </Typography>
          {props.links.map((link: any) => {
            return (
              <IconButton href={link} target="_blank" key={link}>
                {link.includes("github") ? <GitHubIcon /> : <LanguageIcon />}
              </IconButton>
            );
          })}
        </Box>
        <Box bgcolor={"primary.light"}>
          <Typography variant="body1" sx={{ p: 2, borderRadius: 1 }}>
            {props.text}
          </Typography>
          <Typography variant="body2" sx={{ p: 2, borderRadius: 1 }}>
            {props.tech}
          </Typography>
        </Box>
      </Box>
      <img
        src={props.img}
        alt={"lsf"}
        loading="lazy"
        width={screen ? "50%" : "100%"}
        height={"auto"}
      />
    </Stack>
  );
}

export default ProjectItem;
