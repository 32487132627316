import { Box, Typography } from "@mui/material";

function Watermark(props: any) {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Typography variant="body2" sx={{ mb: 2 }}>
        Designed & Built by Thomas Zhou
      </Typography>
    </Box>
  );
}

export default Watermark;
