import { useEffect, useState } from "react";
import theme from "../Themes";

function ScreenSize() {
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function updateDimensions() {
    const width = window.innerWidth;
    setIsDesktop(() => {
      if (width > theme.breakpoints.values.md) {
        return true;
      }
      return false;
    });
  }
  return isDesktop;
}

export default ScreenSize;
