const experience = [
    {
        company: 'Arctic Wolf Networks',
        role: 'Software Engineer',
        duration: 'May 2024 - August 2024',
        notes: [
            'Engineered REST API endpoints with Go, PostgreSQL, and OpenAPI incorporating e2e, integration, and unit tests',
            'Cut API query times by 66% by reducing database calls through schema migrations and backend refactoring',
            'Leveraged AWS, Kubernetes, and Jenkins for data verification and automated deployment of code',
            'Collaborated with UI/UX developers to engineer new UI components using React.js, TypeScript, and Playwright',
        ],
    },
    {
        company: 'University Health Network',
        role: 'Software Developer',
        duration: 'September 2023 - December 2023',
        notes: [
            'Decreased research data querying time by over 70% by creating new Next.js REST API implementing caching',
            'Directed development of new research summary website using TypeScript, Next.js, PostgreSQL, Java, and Docker',
            'Architected static-site generation algorithms using Apache Velocity, cutting development time of new sites by 80%',
            'Reduced intranet website’s loading time by 40% through refactoring and optimizing 2000+ lines of JavaScript code',
        ],
    },
    {
        company: 'Tulip',
        role: 'Software Developer',
        duration: 'September 2022 - December 2022',
        notes: [
            'Reduced client onboarding time by 73% via redesigning existing ETL pipelines using AWS, Datadog, and Workato',
            'Engineered webhooks with TypeScript and Node.js for Tulip’s clienteling application used by 1500+ stores',
            'Led development of REST API connector SDK with Ruby, reducing processing time of inbound documents by 92%',
            'Designed Jest unit tests achieving over 90% code coverage and debugged errors using GCP, REST APIs, and SQL',
        ],
    },
    {
        company: 'Postalgia Inc',
        role: 'Software Developer',
        duration: 'January 2022 - May 2022',
        notes: [
            'Led design and development of internal account management software using the React Framework, decreasing average job completion time by 80%',
            'Constructed an accompanying REST API using Spring Boot which integrates the Google Drive and Sheets APIs',
            'Optimized processing time of existing algorithms by up to 85%, reducing average print proof generation time from 12s to 2s',
            'Implemented an OpenCV computer vision program to detect 1mm dot codes on printed note cards',
            'Containerized and deployed production software using Docker onto 40 robotic printing machines',
        ],
    },
    {
        company: 'Waterloop',
        role: 'Mechanical Subteam',
        duration: 'September 2021 - Present',
        notes: [
            'Engineered and manufactured a prototype ring encoder capable of detecting speeds up to 50m/s using Solidworks and programmed Arduino microcontroller using C++',
            'Modeled and 3D printed parts for electronics enclosure using Solidworks and CURA',
            'Prototyped and designed new lateral guidance system for G6 hyperloop pod',
        ],
    },
    {
        company: 'FRC Team 4914',
        role: 'Build and Design Leader',
        duration: 'September 2017 - June 2021',
        notes: [
            'Coordinated the design and manufacturing process with a team of 20 to construct a 120 pound robot within 2 months',
            'Directed the design of the robot using Autodesk Inventor CAD software and organized and led workshops to train new members, which led to a 50% increase in member retention',
            'Reduced robot construction time by 25% by creating new organizational framework and communication channels with supervisors',
            'Developed an auto-aim program that using Python and OpenCV to autonomously orient the robot and calculate ball shooter speed with an accuracy of 86%',
        ],
    },
];

export default experience;
